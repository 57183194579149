import TlsDepreciationMessageBanner from "../components/TlsDepreciationMessageBanner";
import PreventUserLoginBanner from "../components/PreventUserLoginBanner";
import CookieSettingsModal from "../components/CookieSettingsModal";
import CookieBanner from "../components/CookieBanner";
import FeatureManager from "../utilities/FeatureManager";

$(document).ready(function () {
    if (!!window.OverDrive.EnableTlsSiteMessage === true) {
        var tlsBanner = new TlsDepreciationMessageBanner();
    }
    var preventLoginBanner = new PreventUserLoginBanner();

    $(".cookies-modal-button, .cs-footer_cookie-modal").on('click', () => {
        var modal = new CookieSettingsModal({ showMessage: true });
    });

    if (CookieSettingsModal.shouldShowModalImmediately()) {
        var modal = new CookieSettingsModal({ showMessage: false, dismissable: false });
    } else if (CookieBanner.shouldShowBanner()) {
        var cookieBanner = new CookieBanner();
    }

    if (window.OverDrive.UsingContentStackFooter) {
        $(".site-footer__section .headerContainer").keypress((e) => {
            var key = e.which;
            if (key == 13 || key == 32)  // the enter and space key codes
            {
                $(e.currentTarget).click();
                e.preventDefault();
                return false;
            }
        }); 

        $(".site-footer__section .headerContainer").on("click", (e) => {
            var target = $(e.currentTarget.parentElement);

            var items = target.find("ul.site-footer__list");
            var openTab = $("ul.site-footer__list:not(.footer-items-hide)");

            if (items.hasClass("footer-items-hide")) {
                if (openTab.length > 0) {
                    openTab.first().addClass("footer-items-hide");
                }
                items.removeClass("footer-items-hide");
            } else {
                items.addClass("footer-items-hide");
            }

        });
    }

    if (!!window.OverDrive.AddLibbyBlogTab === true) {
        $(".u_search__query").on("input", () => {
            if (!$(".tt-dropdown-menu").hasClass("libbyBlogLinkStylingUpdates")) {
                $(".tt-dropdown-menu").addClass("libbyBlogLinkStylingUpdates")
            }
        });
    }

    window.OverDrive.featureManager = new FeatureManager(window.OverDrive.FeatureFlags);
});