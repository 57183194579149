export default class Cookies {
    static get(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    static set(name, value, maxAgeMinutes) {
        if (value === null || typeof (value) === 'undefined') {
            throw Error('No null, undefined, or empty values in cookies please');
        }
        const cookieDomain = location.hostname === 'localhost' ? 'localhost' : 'overdrive.com'
        document.cookie = `${name}=${value};secure;${maxAgeMinutes ? `Max-Age=${maxAgeMinutes * 60};` : ''};domain=${cookieDomain};path=/`;
    }

    static erase(name) {
        Cookies.set(name, '', -1);
    }
}