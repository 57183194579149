import Cookies from "../utilities/Cookies";
import CookieSettingsModal from "./CookieSettingsModal";

export default class CookieBanner {
    constructor() {
        this.targetEle = $(".cookie-banner");
        this.render();
    }

    buildTemplate() {
        const siteCopy = (key) => window.OverDrive.SiteCopy.get(key);
        return `
            <div role="alert" class="banner-body" tabindex="-1">
                <p>
                    ${siteCopy("cookieBanner.notice")}
                </p>
                <button class="close" aria-label="${siteCopy("close")}"> &times; </button>
            </div>
        `;
    }

    render() {
        this.targetEle.empty();
        this.targetEle.append(this.buildTemplate());
        this.targetEle.show();
        this.targetEle.find(".banner-body").trigger("focus");

        $(".cookie-banner .close, body>:not(.cookie-banner)").on("click", () => {
            // "body" handles soft dismiss, blocked by below if clicking the banner
            this.consentAndClose();
        });

        $(".cookie-banner").on("click", (e) => {
            // clicking anywhere on the banner will propagate to the "body" listener otherwise
            e.stopPropagation();
        });

        $(".banner-cookie-button").on("click", () => {
            var modal = new CookieSettingsModal({ showMessage: true, implicitConsent: true });
            this.close();
        });

        $(".cookie-banner .close, .banner-cookie-button").on("keydown", (e) => {
            if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                $(e.target).trigger("click");
            }
        });

        this.targetEle.on('keydown', (e) => {
            this._keepFocus(e);
        });

        this.targetEle.on('keypress', '*', (e) => {
            if (e.key === "Tab") {
                this._keepFocus(e);
            }
        });
    }

    consentAndClose() {
        CookieSettingsModal.consentImplicitly();
        this.close();
    }

    close() {
        // remove soft dismiss listener
        $("body>:not(.cookie-banner)").off("click");
        this.targetEle.hide();
    }

    _keepFocus(e) {
        const activeElem = $(document.activeElement);
        const alertFocus = this.targetEle.find(".banner-body");
        const firstFocusable = this.targetEle.find("a").first();
        const lastFocusable = this.targetEle.find("button");

        if (e.key === "Escape") {
            this.consentAndClose();
        } else if (
            (activeElem.is(firstFocusable) || activeElem.is(alertFocus)) &&
            e.key === "Tab" &&
            e.shiftKey
        ) {
            e.preventDefault();
            lastFocusable.trigger("focus");
        } else if (
            $(activeElem).is(lastFocusable) &&
            e.key === "Tab" &&
            !e.shiftKey
        ) {
            e.preventDefault();
            firstFocusable.trigger("focus");
        }
    }

    static shouldShowBanner() {
        return !window.OverDrive.isGdprCountry
            && Cookies.get("od_track") === null
            && window.location.pathname !== "/cookie-settings/edit";
    }
}