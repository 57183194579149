import Cookies from "../utilities/Cookies";

export default class CookieSettingsModal {
    constructor({ showMessage, dismissable = true, implicitConsent = false }) {
        const odTrackCookie = Cookies.get("od_track");
        const trackingDefault = window.OverDrive.isGdprCountry ? false : true;

        this.allowPerformanceCookies = odTrackCookie === null ? trackingDefault : !!(odTrackCookie & 1);
        this.allowUserBehaviorCookies = odTrackCookie === null ? trackingDefault : !!(odTrackCookie & 2);

        this.showMessage = showMessage;
        this.dismissable = dismissable;
        this.implicitConsent = implicitConsent;

        this.targetEle = $(".modal-backdrop");
        this.render();
    }

    buildTemplate() {
        const getCopy = (key) => window.OverDrive.SiteCopy.get(key);
        return `
            <div class="CookieSettingsModal" id="cookie-modal" role="dialog" aria-modal="true">
                <header>
                    <h1 class="modal-header-text">${getCopy("cookieSettings.cookieSettingsHeader")}</h1>
                </header>
                ${this.dismissable ? `<button class="close"> &times; </button>` : ''}
                <div class="modal-body">
                    <p class="cookie-modal-description">${getCopy("cookieSettings.description")}</p>
                    <label class="c_half cookies">
                        <input type="checkbox" disabled="disabled" checked="checked">
                        ${getCopy("cookieSettings.requiredCookiesLabel")}
                        <p class="cookie-description">${getCopy("cookieSettings.requiredCookiesDescription")}</p>
                    </label>
                    <label class="c_half cookies">
                        <input name="performance-cookie" type="checkbox" ${this.allowPerformanceCookies ? `checked="checked"` : ``}>
                        ${getCopy("cookieSettings.performanceCookiesLabel")}
                        <p class="cookie-description">${getCopy("cookieSettings.performanceCookiesDescription")}</p>
                    </label>
                    <label class="c_half cookies">
                        <input name="behavior-cookie" type="checkbox" ${this.allowUserBehaviorCookies ? `checked="checked"` : ``}>
                        ${getCopy("cookieSettings.researchCookiesLabel")}
                        <p class="cookie-description">${getCopy("cookieSettings.researchCookiesDescription")}</p>
                    </label>
                    
                    <p class="cookie-policy">${getCopy("cookieSettings.learnMoreAboutCookies")}</p>
                    <p class="privacy-policy">${getCopy("cookieSettings.learnMoreAboutPrivacy")}</p>
                </div>

                <button class="save-button">${getCopy("cookieSettings.confirm")}</button>
            </div>
        `;
    }

    _consentAndClose() {
        if (this.dismissable) {
            if (this.implicitConsent) {
                CookieSettingsModal.consentImplicitly();
            }
            this.close();
        }
    }

    _getFirstFocusableElement() {
        if (this.dismissable) {
            return $(".CookieSettingsModal .close");
        } else {
            return $(".CookieSettingsModal input")[1];
        }
    }

    _keepFocus(e) {
        const activeElem = $(document.activeElement);
        const firstFocusable = this._getFirstFocusableElement();
        const lastFocusable = this.targetEle.find(".save-button");

        if (e.key === 'Escape') {
            this._consentAndClose()
        } else if (
            activeElem.is(firstFocusable) &&
            e.key === 'Tab' &&
            e.shiftKey
        ) {
            e.preventDefault();
            lastFocusable.focus();
        } else if (
            $(activeElem).is(lastFocusable) &&
            e.key === 'Tab' &&
            !e.shiftKey
        ) {
            e.preventDefault();
            firstFocusable.focus();
        }
    }

    render() {
        this.targetEle.empty();
        this.targetEle.append(this.buildTemplate());
        this.targetEle.show();

        // clear render pipeline, otherwise click handler doesn't register
        setTimeout(() => {
            $(".CookieSettingsModal .close, .modal-backdrop").on("click", () => {
                this._consentAndClose();
            });
        }, 0);

        this.targetEle.on('keydown', (e) => {
            this._keepFocus(e);
        });

        this.targetEle.on('keypress', '*', (e) => {
            if (e.key === 'Tab') {
                this._keepFocus(e);
            }
        });

        this._getFirstFocusableElement().focus();

        $(".CookieSettingsModal").on("click", (e) => {
            e.stopPropagation();
        });

        $('input[name="performance-cookie"]').click((e) => {
            e.stopPropagation();
            this.allowPerformanceCookies = !this.allowPerformanceCookies;

            $('input[name="performance-cookie"]').prop("checked", this.allowPerformanceCookies);
        });

        $('input[name="performance-cookie"]').on('keypress', (e) => {
            if (e.key === 'Enter') {
                e.target.click();
            }
        });

        $('input[name="behavior-cookie"]').click((e) => {
            e.stopPropagation();
            this.allowUserBehaviorCookies = !this.allowUserBehaviorCookies;

            $('input[name="behavior-cookie"]').prop("checked", this.allowUserBehaviorCookies);
        });

        $('input[name="behavior-cookie"]').on('keypress', (e) => {
            if (e.key === 'Enter') {
                e.target.click();
            }
        });

        $(".save-button").on("click", () => {
            $.post("/cookie-settings/edit",
                {
                    allowUserBehaviorCookies: this.allowUserBehaviorCookies,
                    allowPerformanceCookies: this.allowPerformanceCookies,
                    showMessage: this.showMessage
                },
                (data) => {
                    this.close();
                    window.location.replace(window.location);
                }
            );
        });
    }

    close() {
        if (!this.dismissable) return;
        this.targetEle.hide();
        $(".cs-footer_cookie-modal").focus();
        window.location.hash = '';
    }

    static consentImplicitly() {
        if (Cookies.get("od_track") === null) {
            Cookies.set("od_track", 3, 10 * 365 * 24 * 60);
        }
    }

    static shouldShowModalImmediately() {
        return window.OverDrive.isGdprCountry
            && Cookies.get("od_track") === null
            && window.location.pathname !== "/cookie-settings/edit";
    }
}